

















import Component from 'vue-class-component';
import Vue from 'vue';
import BannerCarousel from '@/components/general/BannerCarousel.vue';
import Banner from '@/components/general/Banner.vue'
import SectionAbout from '@/components/home/SectionAbout.vue';
import GroupCarousel from '@/components/general/GroupCarousel.vue';
import BookPromotion from '@/components/general/BookPromotion.vue';
import SectionVideo from '@/components/home/SectionVideo.vue';
import ContactForm from '@/components/home/ContactForm.vue';
import {books} from '@/collections/books';
@Component({
   components:{
    BannerCarousel,
    SectionAbout,
    GroupCarousel,
    SectionVideo,
    ContactForm,
    Banner,
    BookPromotion
   }
})
export default class Home extends Vue {
  //public model: 
  TopBooks : any = []
  created(){
      this.TopBooks = books.filter((el: { top: boolean; }) => el.top);
  }
  public items: Record<string,any> =[
          {
            src: require('@/assets/images/background.jpg'),
            primary: true,
            title: 'Michele Castelli',
            subtitle: 'Venezuela',
            btn: 'contactMe'
          },
          {
            src: require('@/assets/images/venice.png'),
            text: 'Venezia, Italia',
          },
          {
            src: require('@/assets/images/venezuela-2.jpg'),
            text: 'Caracas, Venezuela'
          },
          // {
          //   src: require('@/assets/images/vatican.png'),
          //   text: 'Firenze, Italia',
          // },
          //  {
          //   src: require('@/assets/images/santo-angel.jpg'),
          //   text: 'El salto ángel, Venezuela'
          // }
        ]
  public about:Record<string,any> = [
      {
          src: require('@/assets/images/scrittore.png'),
          position: "vertical",
          about: {
              title: 'about.title',
              subtitle: 'about.subtitle',
              description: 'about.description[0]'
          }
      },
      {
          src: require('@/assets/images/scrittore-2.jpg'),
          icon: 'icon-quotes-left',
          position: "horizontal",
          about:{
              description:'about.description[1]'
          }
      },
      {
          src: require('@/assets/images/scrittore-3.jpg'),
          icon: 'icon-quotes-left',
          position: "vertical",
          about:{
              description:'about.description[2]'
          }
      }
  ]
  
}
