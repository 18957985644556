


















import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';

import BookCard from '@/components/cards/BookCard.vue';
@Component({
   components:{
       BookCard
   }
})
export default class GroupCarousel extends Vue {
    @Prop() items!: Record<string,any>;
    @Prop() title?: string;

}
