





















































import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import SvgIcon from '@/components/general/SvgIcon.vue';
import axios from "axios";

@Component({
   components:{
       SvgIcon
   }
})
export default class Form extends Vue {
  public name = '';
  public message = '';
  public email ='';
  public endpoint: string = 'https://formspree.io/f/xwkwqqdg';
  success = false
  error = false
  visibility = true;
  errorMessage : any = ''
  loadingSpinner = false;
  
    get verifySpinner(): boolean{
        return this.loadingSpinner;
    }

    sendEmail() {    
     if (this.name == '' || this.message == '' || this.email == ''){
          this.error = true
          this.errorMessage =  this.$t('form.emptyField') 
      }
      else {
          this.loadingSpinner = true;
          axios.post(
          this.endpoint,
          {
            nombre: this.name,
            mensaje: this.message,
            email: this.email
          },
          //  eslint-disable-next-line no-unused-vars
        ).then((response) => {
          this.name =''
          this.message =''
          this.email=''
          this.success = true
         this.loadingSpinner = false;
        // this.$router.push({ path: '/success' });
        }).catch((error) => {       
          if (error.response) {
            // eslint-disable-next-line no-alert
            this.loadingSpinner = false;
           // window.alert(this.loadingSpinner);
            this.error=true
            if (error.response.data.errors[0].code === 'EMPTY'){
              this.errorMessage = this.$t('form.emptyField') 
            }
            else this.errorMessage =  this.$t('form.error') 
          }
        });
      }
      
    }
}
