





















import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import SvgIcon from '@/components/general/SvgIcon.vue';
import SquaredImage from '@/components/general/SquaredImage.vue';
@Component({
   components:{
       SvgIcon,
       SquaredImage
   }
})
export default class SectionAbout extends Vue {
    @Prop() about!: Object;
    @Prop() reverse?: boolean;
    show = false;
 /* test(e: any){
      //  console.log(e.target)
    } */
    mounted(){
        this.show =true
       // document.addEventListener('scroll', this.test)
    } 
}
