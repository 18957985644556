














import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
   components:{
    
   }
})
export default class Banner extends Vue {
    @Prop() item! : any;

}

