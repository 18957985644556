














import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import Video from '@/components/general/Video.vue';
import {interviews} from '@/collections/interviews';
@Component({
   components:{
       Video
   }
})
export default class SectionVideo extends Vue {
   interviews : Record<any,any> = []
   created(){
       this.interviews = interviews
   }
   goToUrl(url: string){
        this.$router.push({
                name: url
        }).catch(()=>{});
   }
}
