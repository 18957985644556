









import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import firebase from 'firebase'

@Component({
   components:{
   }
})
export default class BookCard extends Vue {
    @Prop() item! : any;
    image : string = '';
    storage = firebase.storage();
     showDetail(id: number){    
         this.$router.push({
                name: 'BooksDetail',
                params: { id: '' + id }
            }).catch(()=>{});
    }
     getImage(id: number){  
        this.storage.ref(`obras/${id}/${id}.png`).getDownloadURL().then((url)=>{
           this.image =  url
        })
      return this.image;
    }
}

