
















import {Component } from 'vue-property-decorator';
import Vue from 'vue';
import SvgIcon from './SvgIcon.vue';
@Component({
   components:{
        SvgIcon
   }
})
export default class BookPromotion extends Vue {


}

