import { render, staticRenderFns } from "./BookPromotion.vue?vue&type=template&id=160188ce&scoped=true&"
import script from "./BookPromotion.vue?vue&type=script&lang=ts&"
export * from "./BookPromotion.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160188ce",
  null
  
)

export default component.exports