


































import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
   components:{
    
   }
})
export default class BannerCarousel extends Vue {
    @Prop() items! : Record<string,any>;
}

